/* Container styling */
.user-info-container {
  background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
  padding: 20px;
  padding-top: 5px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: system-ui, -apple-system, sans-serif;
 
  margin: 0 auto;

}

/* Header styling */
.user-info-container h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 0px;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  margin-top: 10px;
  
}

.user-info-container h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2e4848 0%, #a9c7c6 100%);
  border-radius: 2px;
}

/* Email section styling */
.user-info-email {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin: 25px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.user-info-email p {
  font-size: 1.1rem;
  color: #4a5568;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.email-placeholder, .tier-placeholder {
  color: #2e4848;
  font-weight: 500;
  font-style: normal;
}

/* Button container to maintain consistent spacing */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 25px;
}

/* Button styles */
.manage-org-button,
.tier-button,
.personal-info-button, /* Add this selector */
.logout-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Specific button styles */
.manage-org-button {
  background: linear-gradient(90deg, #2e4848 0%, #3d5f5f 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
}

.manage-org-button:hover {
  background: linear-gradient(90deg, #3d5f5f 0%, #2e4848 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
}

.tier-button {
  background: linear-gradient(90deg, #3d5f5f 0%, #4d7373 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(61, 95, 95, 0.15);
}

.tier-button:hover {
  background: linear-gradient(90deg, #4d7373 0%, #3d5f5f 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(61, 95, 95, 0.2);
}

/* New Personal Info button styles */
.personal-info-button {
  background: linear-gradient(90deg, #4d7373 0%, #5e8a8a 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(77, 115, 115, 0.2);
}

.personal-info-button:hover {
  background: linear-gradient(90deg, #5e8a8a 0%, #4d7373 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(77, 115, 115, 0.3);
}

.logout-button {
  background: linear-gradient(90deg, #a9c7c6 0%, #90a5a4 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(169, 199, 198, 0.2);
}

.logout-button:hover {
  background: linear-gradient(90deg, #90a5a4 0%, #a9c7c6 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(169, 199, 198, 0.3);
}


/* Loading state */
.email-placeholder:empty::before {
  content: "Loading...";
  color: #90a5a4;
  font-style: italic;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .user-info-container {
      padding: 25px;
      margin: 20px;
  }

  .user-info-container h2 {
      font-size: 1.5rem;
      margin-bottom: 20px;
  }

  .manage-org-button,
  .tier-button,
  .logout-button {
      padding: 10px 20px;
  }
}