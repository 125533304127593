/* Base modal styles with enhanced backdrop */
.tier-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-start; /* Change from center to flex-start */
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    overflow-y: auto; /* Ensure full content is scrollable */
    padding-top: 30px; /* Add top padding to prevent content from being hidden */
    padding-bottom: 30px; /* Add bottom padding for extra space */
}

.tier-modal-content {
    
    background-color: #ffffff;
    border-radius: 20px;
    height: 90%;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    max-height: calc(100vh - 60px); /* Allow modal to fit within viewport */
    overflow-y: auto; /* Enable internal scrolling for content */
    padding-bottom: 0px;
    margin-bottom: -20px;
}

/* Enhanced header styling */
.tier-modal-content h2 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 0px;
    font-weight: 700;
    position: relative;
    padding-bottom: 0px;
    margin-top: 0;
}

.tier-modal-content h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #2e4848 0%, #a9c7c6 100%);
    border-radius: 2px;
}

/* Container layout improvements */
.tier-options-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 20px;
    flex-wrap: nowrap;
}

/* Enhanced card styling */
.tier-card {
    flex: 1;
    background: #ffffff;
    padding: 30px;
    border-radius: 16px;
    min-width: 200px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #eef2f2;
}

.tier-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

/* Tier-specific styling */
.free-tier {
    background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
}

.single-user-tier {
    background: linear-gradient(145deg, #ffffff 0%, #f2f7f7 100%);
}

.practice-tier {
    background: linear-gradient(145deg, #f2f7f7 0%, #e5efef 100%);
}

.support-info {
    background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
}

/* Typography improvements */
.tier-card h3 {
    color: #2e4848;
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 700;
}

.tier-card p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #4a5568;
    margin-bottom: 15px;
}

.tier-card p strong {
    color: #2e4848;
    font-weight: 600;
}

/* Price styling */
.tier-card p:nth-child(2) {
    font-size: 1.4rem;
    font-weight: 700;
    color: #2e4848;
    margin: 15px 0;
}

/* Enhanced list styling */
.tier-card ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
    flex-grow: 1;
}

.tier-card li {
    margin: 12px 0;
    text-align: left;
    padding-left: 28px;
    position: relative;
    color: #4a5568;
    line-height: 1.5;
}

.tier-card li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #2e4848;
    font-weight: bold;
}

/* Button styling */
.close-modal-button-tier {
    background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
    
    box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
}

.close-modal-button-tier:hover {
    background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
    box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
    transform: translateY(-2px);
}

/* Support info specific styling - updated */
.support-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-wrap: break-word;  /* Added to handle long words/emails */
}

.support-info h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    line-height: 1.4;
}

.support-info p {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 12px;
}

.support-info strong {
    word-break: break-all;  /* Ensures email breaks properly */
    color: #2e4848;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .tier-options-container {
        flex-wrap: wrap;
    }
    
    .tier-card {
        flex: 1 1 calc(50% - 25px);
        min-width: calc(50% - 25px);
    }
    
    .tier-modal-content {
        padding: 30px;
    }
}

@media (max-width: 768px) {
    .tier-modal-content {
        padding: 20px;
    }
    
    .tier-card {
        flex: 1 1 100%;
        min-width: 100%;
    }
    
    .tier-modal-content h2 {
        font-size: 2rem;
        margin-bottom: 30px;
    }
}