/* Container styling */
.note-taker-container {
  background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
  padding: 20px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  font-family: system-ui, -apple-system, sans-serif;
  position: relative; /* Added for absolute positioning of timer */
}

/* Header styling */
.note-taker-container h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  margin-top: 10px;
  text-align: left; /* Align title to the left */
}

.note-taker-container h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0; /* Changed from 50% to align with left title */
  transform: translateX(0); /* Removed horizontal centering */
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2e4848 0%, #a9c7c6 100%);
  border-radius: 2px;
}

/* Button grid container */
.button-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

/* Common button styles */
.button-grid button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #3e5656; /* Subtle single color */
  color: white;
  box-shadow: 0 2px 8px rgba(46, 72, 72, 0.1); /* Softer shadow */
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-grid button:hover {
  background: #354d4d; /* Slightly darker shade on hover */
  transform: translateY(-1px); /* Reduced translate effect */
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15); /* Lighter hover shadow */
}

/* Active button style */
.button-grid button.active {
  background: linear-gradient(90deg, #a9c7c6 0%, #90a5a4 100%);
  box-shadow: 0 6px 15px rgba(169, 199, 198, 0.3);
  transform: translateY(-2px);
}

/* Stop button specific styles */
.button-grid button:last-child {
  background: linear-gradient(90deg, #e53e3e 0%, #c53030 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(229, 62, 62, 0.2);
}

.button-grid button:last-child:hover {
  background: linear-gradient(90deg, #c53030 0%, #e53e3e 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(229, 62, 62, 0.3);
}

/* Timer container styling */
/* Timer container styling */
.timer-container {
  position: absolute; /* Position timer absolutely */
  top: 20px;
  right: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.timer-container span {
  padding: 8px 12px;
  background: #f0f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .note-taker-container {
    padding: 25px;
    margin: 20px;
  }

  .note-taker-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .button-grid button {
    padding: 10px 20px;
  }
}
