.logged-in-container {
  display: grid;
  grid-template-columns: 33% 1fr; /* Left side takes 33% of width, right side takes remaining */
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 100vh;
}



/* Make the BottomLeftNoteTaker and TopRightUserInfo swap positions */
.quadrant:nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}

.quadrant:nth-child(3) {
  grid-row: 2;
  grid-column: 1;
}

/* Make the BottomRightTextViewer taller by extending up */
.quadrant:nth-child(4) {
  grid-row: 1 / span 2;
  grid-column: 2;
  margin-top: 16%;
  min-height: 600px;
}