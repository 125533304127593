.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  width: 600px;
  text-align: center;
  position: relative;
}

.modal-content h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.modal-content h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2e4848 0%, #a9c7c6 100%);
  border-radius: 2px;
}

/* Table Styling */
.members-table {
  width: 100%;
  margin: 25px 0;
  border-collapse: separate;
  border-spacing: 0;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.members-table th {
  background: #2e4848;
  color: #ffffff;
  padding: 15px 20px;
  font-weight: 600;
  text-align: left;
  font-size: 1rem;
}

.members-table td {
  padding: 15px 20px;
  border-bottom: 1px solid #eef2f2;
  color: #4a5568;
  font-size: 0.95rem;
}

.members-table tr:last-child td {
  border-bottom: none;
}

.members-table tr:hover td {
  background-color: #f8fafa;
}

/* Button Styling */
.remove-button {
  background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(220, 53, 69, 0.2);
}

.remove-button:hover {
  background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.3);
}

.add-member-button {
  background: linear-gradient(90deg, #2e4848 0%, #3d5f5f 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin: 20px 0;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
}

.add-member-button:hover {
  background: linear-gradient(90deg, #3d5f5f 0%, #2e4848 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
}

.close-button-org-modal {
  background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(220, 53, 69, 0.2);
}

.close-button-org-modal:hover {
  background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.3);
}

/* Form Styling */
.add-member-form {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.email-input {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #eef2f2;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #f8fafa;
  color: #2e4848;
}

.email-input:focus {
  outline: none;
  border-color: #2e4848;
  box-shadow: 0 0 0 3px rgba(46, 72, 72, 0.1);
}

.email-input::placeholder {
  color: #90a5a4;
}

.send-invite-button {
  background: linear-gradient(90deg, #2e4848 0%, #3d5f5f 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
  width: 100%;
}

.send-invite-button:hover {
  background: linear-gradient(90deg, #3d5f5f 0%, #2e4848 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
}

.error-message {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.1);
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 0.9rem;
  margin: 10px 0;
  width: 100%;
  text-align: center;
}

/* Loading State */
.members-table tr td[colspan="2"] {
  padding: 30px;
  text-align: center;
  color: #90a5a4;
  font-style: italic;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content {
      width: 90%;
      padding: 25px;
      margin: 20px;
  }

  .modal-content h2 {
      font-size: 1.5rem;
  }

  .members-table th,
  .members-table td {
      padding: 12px 15px;
  }

  .add-member-form {
      padding: 15px;
  }
}