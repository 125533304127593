/* Container styling */
.text-viewer-container {
  background: white;
  padding: 25px;
  padding-top: 5px;
  padding-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  font-family: system-ui, -apple-system, sans-serif;
  height: 95%;
  margin: 0 auto;
  text-align: left;
  /* Consistent alignment */
}

/* Header styling */
.text-viewer-header {
  font-size: 2rem;
  color: #1a1a1a;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
}

.text-viewer-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2e4848 0%, #a9c7c6 100%);
  border-radius: 2px;
}



/* Markdown and textarea styling */
.text-viewer-markdown,
.text-viewer-textarea {
  width: 98%;
  height: 75%;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #4a5568;
  background-color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.text-viewer-markdown {
  overflow-y: auto;
  cursor: pointer;
  text-align: left;
  /* Consistent alignment */
}

.text-viewer-textarea {
  resize: none;
  outline: none;
}

.text-viewer-textarea:focus {
  border-color: #2e4848;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.2);
}

/* Button container */
.text-viewer-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

/* Button styles */
.text-viewer-buttons button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #2e4848 0%, #3d5f5f 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text-viewer-buttons button:hover {
  background: linear-gradient(90deg, #3d5f5f 0%, #2e4848 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
}

/* Disabled button */
.text-viewer-buttons button:disabled {
  background: linear-gradient(90deg, #a9c7c6 0%, #90a5a4 100%);
  cursor: not-allowed;
  opacity: 0.6;
  box-shadow: none;
  transform: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .text-viewer-container {
    padding: 25px;
    margin: 20px;
  }

  .text-viewer-container h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .text-viewer-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .text-viewer-buttons button {
    padding: 10px 20px;
  }
}


/* Existing CSS remains the same until the dropdown additions below */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #2e4848 0%, #3d5f5f 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(46, 72, 72, 0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.dropdown-button:hover {
  background: linear-gradient(90deg, #3d5f5f 0%, #2e4848 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(46, 72, 72, 0.2);
}

.dropdown-content {
  display: none;
  position: absolute;
  bottom: calc(100% + 8px);
  /* Position above the button */
  left: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 8px;
  z-index: 1000;
}

.dropdown-content button {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  color: #4a5568;
  font-weight: 500;
  border-radius: 6px;
  background: transparent;
  box-shadow: none;
  bottom: 100%;
  /* Remove the gap */
  padding-bottom: 8px;
  /* Add padding to extend hover area */
}

/* Add invisible bridge between button and content */
.dropdown::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 8px;
  background: transparent;
}

.dropdown-content button:hover {
  background: #f8f9fa;
  color: #2e4848;
  transform: none;
  box-shadow: none;
}

/* Show dropdown on hover of either button or content */
.dropdown:hover .dropdown-content,
.dropdown-content:hover {
  display: block;
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
    /* Changed to move upward */
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dropdown {
    width: 100%;
  }

  .dropdown-button {
    width: 100%;
  }

  .dropdown-content {
    width: 100%;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}